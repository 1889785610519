<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :editComponent="componentForEditing"
      :delete_url="delete_url"
      ref="dataTable"
    >
      <template v-slot:rowActionsDropdown="{ item }">
        <v-btn
          class="d-block w-100"
          text
          @click="
            () => {
              testAiModule(item);
            }
          "
          >Test
        </v-btn>
      </template>
    </DataTable>
    <Test ref="testModal" />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import AddItem from "@/own/components/settings/openAiIntegration/AddItem.vue";
import EditItem from "@/own/components/settings/openAiIntegration/EditItem";
import Test from "@/own/components/settings/openAiIntegration/Test.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/openAiIntegration.module";

export default {
  name: "OpenAiIntegration",
  components: { DataTable, Test },
  data: () => ({
    componentForEditing: EditItem,
    componentForAdding: AddItem,
    delete_url: `${process.env.VUE_APP_BASE_URL}/settings/ai_modules/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getOPENAIINTEGTableFilters",
      getTableState: "getOPENAIINTEGTableState",
      getTableData: "getOPENAIINTEGTableData",
      getTableHeaders: "getOPENAIINTEGTableHeaders",
      getTableProperties: "getOPENAIINTEGTableProperties",
      getTableExportUrl: "getOPENAIINTEGExportUrl",
      getTablePermissions: "getOPENAIINTEGTablePermissions",
      getUploadType: "getOPENAIINTEGUploadType",
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
    },
    testAiModule(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$nextTick(() => {
        this.$refs.testModal.toggleModal();
      });
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
