<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1600px"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Test AI Module</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form class="ma-0 pa-0 row">
          <div class="col-12 col-md-6">
            <h4
              class="mb-0 font-weight-bolder pb-2 text-muted poppins-l text-uppercase"
            >
              Input
            </h4>
            <textarea
              v-model="formData"
              class="simple-textarea w-100 json-textarea jsoneditor"
              style="
                border: 0.4px solid grey;
                border-radius: 2px;
                padding: 8px;
                max-height: 154px;
                overflow-y: auto;
                resize: none;
              "
              rows="8"
            ></textarea>
            <!-- <v-jsoneditor
              v-model="formData"
              :options="{
                ...options
              }"
            /> -->
            <div class="text-left pt-4">
              <button
                class="btn btn-info px-5 py-3 ls1 h6 font-weight-bolder"
                @click.prevent="loadDataFromServer"
              >
                Test
              </button>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <h4
              class="mb-0 font-weight-bolder pb-2 text-muted poppins-l text-uppercase"
            >
              Output
            </h4>
            <v-jsoneditor
              v-model="serverData"
              :options="{
                ...options,
                onEditable: function () {
                  return false;
                },
              }"
            />
            <!-- <textarea
              v-model="serverData"
              disabled
              class="simple-textarea w-100"
              style="border: 1px solid grey; border-radius: 10px; padding: 8px"
              rows="15"
            ></textarea> -->
            <div class="text-right pt-4">
              <button
                class="btn btn-light px-5 py-3 ls1 h6 font-weight-bolder"
                @click.prevent="resetEditForm"
              >
                Clear
              </button>
            </div>
          </div>
          <!-- end address -->
        </form>
        <!--end::Body-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import VJsoneditor from "v-jsoneditor/src/index";

import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";

export default {
  name: "Test",
  components: { VJsoneditor },
  props: [],
  data: () => ({
    options: {
      mode: "text",
      navigationBar: false,
      mainMenuBar: false,
      search: false,
      statusBar: false,
    },
    dialog: false,
    serverData: "",
    formData: "",
  }),
  methods: {
    pageLoader(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    loadDataFromServer() {
      let data = {};
      try {
        data = {
          id: this.$store.getters.getItemForAction.id,
          ...JSON.parse(this.formData),
        };
      } catch {
        data = {
          id: this.$store.getters.getItemForAction.id,
          address: this.formData,
        };
      }
      this.pageLoader(true);
      ApiService.post("/settings/ai_modules/test_ai_address_correction", data)
        .then((response) => {
          // this.serverData = JSON.stringify(response.data.data);
          this.serverData = response.data.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      this.formData = "";
      this.serverData = "";
    },
    toggleModal() {
      if (this.dialog) {
        this.resetEditForm();
      }

      this.dialog = !this.dialog;
    },
  },
  computed: {},
};
</script>

<style>

</style>
